// BASE -- Navigation and Global Stuff here

html {
	height: 100%;
	// margin:0;
	// &.height-100 {
	// 	height: 100%;
	// }
}
body {
    position: relative;
    display: flex;
    flex-direction: column;
    height:100%;
    justify-content: center;
    align-items: center;
    margin:0;
    // overflow-y: scroll;
}
.site {
	height:auto;
	width:100%;
	height: 100%;
	position: relative;
	z-index:1;
	.nav + & {
		margin-top: 14em;
		@media all and (max-width: $mq-1024) {
			margin-top: 0;
		}
	}
}
.section {
	display:inline-block;
	width:100%;
	position: relative;
	margin-top: 12em;

	@media all and (max-width: $mq-1024) {
		margin-top: 8em;
	}

	&.has_marginSmall {
		// margin-top:5.4em;
		margin-top:5em;
		@media all and (max-width: $mq-1024) {
			margin-top: 3em;
		}
	}
	.introElement + .site &:first-child {
		margin-top: 20em;
		@media all and (max-width: $mq-1024) {
			margin-top: 8em;
		}
	}
}
// .introElement + .site:first-child {
// 	margin-top: 20em !important;
// }
.inner-wrapper {
	// max-width:1280px;
	max-width:1130px;
	width:90%;
	margin:0 auto;
}

nav {
	transition: background-color .4s ease-in-out;
	background-color: $beige;
	&.bgWhite {
		background-color: $white;
	}
	&.is_scrolled {
		// height:76px;
		height:100px;
		transition: height 0.4s ease-in-out 0.6s, background-color .4s ease-in-out;
		// @media all and (max-width: $mq-1024) {
		//     height: auto;
		// }
		.logo-wrapper {
			svg {
				.letters {
					opacity:0;
					transition: opacity 0.4s ease-in-out;
				}
				.circle {
					transform: translate(-72%,0);
					transition: transform 0.4s ease-in-out 0.2s;
				}
			}
		}
		.menu-items {
			// width:68%;
			width:60%;
			margin-left:0;
			transition: width 0.4s ease-in-out 0.2s, margin-left 0.4s ease-in-out 0.2s;

			@media all and (max-width: $mq-1024) {
			    width: 100%;
			}
		}
	}

	@media all and (max-width: $mq-1024) {
	    position: fixed !important;
	    // bottom: 0;
	    // top: auto !important;
	    height: 100vh !important;
	    max-height:0;
	    overflow: hidden;
	    transition: max-height 0.25s ease-in-out, height 0.25s ease-in-out, background-color .4s ease-in-out !important;
	}
	&.is_open {
		@media all and (max-width: $mq-1024) {
		    max-height:100vh;
		}
		.menu-items {
			@media all and (max-width: $mq-1024) {
				opacity:1;
				// transform: translate(0, 0);
				transform: translate(0, -6.5vh);
			}
			.menu-item {

			}
		}
		.lang-switcher {
			@media all and (max-width: $mq-1024) {
				opacity:1;
				transform: translate(-50%, 0);
			}
		}
	}
	.inner-wrapper {
    	@media all and (max-width: $mq-1024) {
		    flex-direction: column;
	    	height: 100%;
    	    justify-content: center;
		}
	}
	.logo-wrapper {
		svg {
			.letters {
				opacity: 1;
				transition: opacity 0.4s ease-in-out 0.2s;
			}
			.circle {
				transform: translate(0,0);
				transition: transform 0.4s ease-in-out;
			}
		}
		@media all and (max-width: $mq-1024) {
			display:none;
		}
	}
	.menu-items {
		display:flex;
		align-items:center;
		width:55%;
		// margin-left:15em;
		margin-left:11%;
	    justify-content: space-between;
        transform: translateZ(1px);
	    backface-visibility: hidden;
	    transition: width 0.4s ease-in-out, margin-left 0.4s ease-in-out;

	    @media all and (max-width:1180px) {
	    	margin-left:4%;
	    	width:65%;
	    }
	    @media all and (max-width: $mq-1024) {
    	    flex-direction: column;
		    width: 100%;
	        height: 60%;
	        max-height: 30em;
		    margin-left: 0;

			opacity:0;
			transform: translate(0, 50px);
			transition: transform 0.25s ease-in-out 0.25s, opacity 0.15s ease-in-out 0.25s;
	    }
		.menu-item {
			@include f-frutiger-l-15;
			text-transform: uppercase;
			color:$darkBlue;
			cursor:pointer;
			transition: width 0.2s ease-in-out;
		    transform: translateZ(1px);
    		backface-visibility: hidden;
			position: relative;
			&:hover {
				// font-family:$frutigerRoman;
				&:after {
					opacity: 1;
				}
			}
			// &:after {
			// 	display: block;
			// 	content: attr(data-boldHolder);
			// 	font-family:$frutigerRoman;
			// 	height: 0;
			// 	overflow: hidden;
			// 	visibility: hidden;
			// }

			&:after {
				display: block;
				content: "";
				position: absolute;
				left: 0;
				bottom:0;
				height:1px;
				width: 100%;
				background-color: $darkBlue;
				opacity: 0;
				transition: opacity 0.25s linear;
			// 	height: 0;
			// 	overflow: hidden;
			// 	visibility: hidden;
			}



			// .menu-item-inner {
			// 	position: relative;
			// }
			&.hide-desk {
	    	    display:none;
				@media all and (max-width: $mq-1024) {
		    	    display:block;
			    }
			}
		}
	}
	.lang-switcher {
		display: flex;
	    align-items: center;
	    // margin-left: 80px;
	    margin-left: 6%;
	    &:before {
	    	content: "";
	    	position: absolute;
	    	left: 50%;
	    	transform: translate(-50%,0);
			top: 0;
			height: 100%;
			width: 1px;
			background-color: $darkBlue;
			display:none;
	    }
	    @media all and (max-width:1180px) {
	    	margin-left:4%;
	    }
	    @media all and (max-width: $mq-1024) {
			// display:none;
		    position: absolute;

		    margin-left: 0;
		    left: 50%;
		    transform: translate(-50%,0);
		    // bottom: 10vh;
		    bottom: 15vh;

		    opacity:0;
			transform: translate(0, 50px);
			transition: transform 0.25s ease-in-out 0.35s, opacity 0.15s ease-in-out 0.35s;

			height: 22px;
			&:before {
		    	display: block;
		    }
		}
	}
	.lang-item {
		@include f-frutiger-l-15;
		text-transform: uppercase;
		color:$darkBlue;
		&:first-of-type {
			@media all and (max-width: $mq-1024) {
				margin-right: 1em;
			}
		}
		&.is_active {
			display: none;
			@media all and (max-width: $mq-1024) {
				display: inline-block;
				font-family: $frutigerRoman;
				font-weight: 500;
				pointer-events: none;
			}
		}
	}
}
.mobile-header {
    width: 100%;
    // position: relative;
    position: fixed;
    top: 0;
    background-color: $beige;
	display: none;
	transition: background-color .4s ease-in-out, transform 0.4s ease-in-out;;
	transform: translate(0, 0);
	min-height: 68px;
	// background-color: transparent;
    @media all and (max-width: $mq-1024) {
		display: block;
    	z-index: 3;
	}
	&.is_back {
		@media all and (max-width: $mq-1024) {
		    background-color: white;
		}
		.logo {
			@media all and (max-width: $mq-1024) {
			    // display: none;
			}
		}
	}
	&.bgWhite {
		background-color: $white;
	}
	&.is_hidden {
		transform: translate(0, -100%);
	}
	&.is_scrolled {
		.logo {
		    margin-top: 1em;
			margin-bottom: 0.5em;
			svg {
			    width: 184px;
    			height: 50px;
				.letters {
					opacity:0;
					transition: opacity 0.4s ease-in-out;
				}
				.circle {
					transform: translate(-72%,0);
					transition: transform 0.4s ease-in-out 0.2s;
				}
			}
		}
	}
	&.is_open {
		background-color: transparent;
		.logo {
			@media all and (max-width: $mq-1024) {
				opacity: 0;
				transform: translate(0, -50px);
				// transition: transform 0.25s ease-in-out 0.25s, opacity 0.15s ease-in-out 0.25s;
			}
		}
		.burger {
			.hamburger {
				@extend .is-active;
			}
		}
	}
    .inner-wrapper {
    	position: relative;
    	display: flex;
    	width: 90%;
    }


	.logo {
		margin-top: 3em;
		transition: margin-top 0.4s ease-in-out 0.6s;
		svg {
		    width: 212px;
		    height: 57px;
		    transition: height 0.4s ease-in-out, width 0.4s ease-in-out;
		    .letters {
				opacity: 1;
				transition: opacity 0.4s ease-in-out 0.2s;
			}
			.circle {
				transform: translate(0,0);
				transition: transform 0.4s ease-in-out;
			}
		}
		@media all and (max-width: $mq-1024) {
			opacity: 1;
			transform: translate(0, 0);
			transition: transform 0.25s ease-in-out 0.25s, opacity 0.15s ease-in-out 0.25s, margin-top 0.4s ease-in-out 0.6s;
		}
	}
	.burger {
	    // padding: 3em;
	    padding: 3em 0 3em 3em;
        position: absolute;
	    right: 0;
	    top: 0;
	    cursor: pointer;
	    .hamburger {

	    }
		svg {
		    width: 20px;
			height: 15px;
		}
	}
}

.introElement {
	display:flex;
	justify-content:center;
	align-items:center;
    margin-top: -70px;

	@media all and (max-width: $mq-1024) {
    	margin-top: 0;
	}
	
	.text {
		@include f-canelaWeb-l-90;
		text-align:center;
		color: $darkBlue;
	}
	.arrow_down{
		width:30px;
		height:30px;
		position: absolute;
		padding:2em;
	    right: 6.5em;
    	bottom: 4em;
    	box-sizing:content-box;
    	cursor:pointer;
    	animation: bounce 2s infinite;
    	@media all and (max-width: $mq-1024) {
		    padding: 2em 0 2em 2em;
		    right: 5%;
		    z-index: 2;
		    bottom: 8em;
    	}	
	}
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-15px);
	}
	60% {
		transform: translateY(-7.5px);
	}
}

.introBack {
	margin-top:140px;
	width:100%;
    text-align: left;
    @media all and (max-width: $mq-1024) {
		margin-top: 0;
		margin-top: 100px;
	}
	.inner-wrapper {
		@include f-frutiger-l-14;
		color: $darkBlue;
		text-transform: uppercase;

	    margin: 4em auto 0 auto;
	    text-align: left;
	    padding: 1em 1em 1em 0;
	    display:flex;
	    @media all and (max-width: $mq-1024) {
		    padding: 0 1em 1em 0;
    		margin-top: 2.5em;
		}
		svg {
			width: 20px;
			height: 20px;
			margin-right: 1em;
		}
	}
}

.site-footer {
	background-color: $beige;
	padding:8em 0 6em 0;
	margin-top: 14em;
	@media all and (max-width: $mq-1024) {
	    padding: 6em 0 6em 0;
	}
	.inner-wrapper {
		.row {
			&-logo {
				text-align:right;
				@media all and (max-width: $mq-1024) {
			        text-align: left;
				}
				img {
					width:7.7em;
					height:7.2em;
					@media all and (max-width: $mq-1024) {
			            left: -0.7em;
						position: relative;
					}
				}
			}
			&-company_text {
				@include f-frutiger-l-19;
				color: $darkBlue;
			    margin-top: 0.2em;
			    @media all and (max-width: $mq-1024) {
		            margin-top: 1.2em;
				}
			}
			&-infos {
			    display: flex;
				justify-content: space-between;
				margin-top: 3em;
				@media all and (max-width: $mq-1024) {
	                flex-direction: column;
    				margin-top: 1.2em;
				}
				.infos-column {
					&-1,
					&-2 {
						@include f-canelaWeb-l-34;
					    line-height: 1.325em;
					    letter-spacing:1px;
						color: $darkBlue;
						a {
							color: $darkBlue;

						}
					}
					&-3 {
					    display: flex;
					    flex-direction: column;
					    justify-content: flex-end;
					    align-items: flex-end;
					    @media all and (max-width: $mq-1024) {
			                display: none;
						}
						.link {
							@include f-frutiger-l-14;
							color: $darkBlue;
							letter-spacing: 2px;
							text-transform: uppercase;
							text-align: right;
						    margin-top: 0.2em;
						    position: relative;
					        display: flex;
    						justify-content: flex-end;
						    &:first-of-type {
						    	margin-top: 0;
						    }
						    &:after {
						    	content: "";
						    	position: absolute;
						    	left:0;
						    	bottom:0;
						    	width:100%;
						    	height: 1px;
						    	background-color: $darkBlue;
						    	opacity: 0;
					    	    transition: opacity 0.2s ease-in-out;
						    }
						    &:hover {
								&:after {
									opacity: 1;
								}
						    }
						}
					}
				}
			}
		}
	}
}

.link {
	&.bigLine {
		position: relative;
		display: inline-block;
		
		&:before {
			content: "";
			position: absolute;
			width: 108%;
			height: 3px;
			background-color: $darkBlue;
		    bottom: -0.4em;
		    left: 50%;
		    transform: translate(-50%, 0);
    		transition: width 0.3s ease-in-out;
		}
		&:hover {
			&:before {
				width: 135%;
			}
		}
	}
}

.section-RefSlider + .section-TextblockBig {
	margin-top: 14em;
}