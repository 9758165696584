// Modul Element - InfoSlider

.section-InfoSlider {
	@media all and (max-width: 960px) {
		&.has_marginSmall {
        	margin-top: 2.07em;
		}
	}
	.inner-wrapper {
		@media all and (max-width: 960px) {
			width:100%;
		}
		.pagination {
			display: flex;
		    justify-content: space-between;
		    // padding-bottom: 25px;
		    border-bottom: 1px solid $darkBlue;
			@media all and (max-width: 960px) {
			    flex-direction: column;
				border-bottom: 0;
			    justify-content: center;
				align-items: center;
			}
			.pagination-item {
				@include f-canelaWeb-l-23;	
				color: $darkBlue;
				position: relative;
				// width: 25%;
				width: 32%;
			    padding: 0 0.5em 25px 0;
			    display: flex;
    			align-items: flex-end;
    			justify-content: center;
    			cursor:pointer;
    			// transition: font-weight 0.75s ease-in-out;
				@media all and (max-width: 960px) {
			        border-bottom: 1px solid #072e67;
    				width: 90%;
				    min-height: 65px;
					padding: 0 0 0.6em 0;
				}

				&-0 {
					justify-content:flex-start;
				}
				&-1 {
					justify-content:center;
					@media all and (max-width: 960px) {
						justify-content:flex-start;
					}
				}
				&-2 {
					justify-content:flex-start;	
				}
				&:nth-of-type(3),
				&:nth-of-type(5){
					@media all and (max-width: 960px) {
					    margin-top: 0.9em;
						// padding: 0 0 0.6em 0;
					}

				}
				// &-2,
				// &-3 {
				// }
    			&:after {
					content: "";
					position: absolute;
					left:0;
					right:0;
					bottom:0;
					top:auto;
					width:0;
					height:4px;
					background-color: $darkBlue;
					transition: width 0.5s ease-in-out;
				}
    			&.is_open {
    				font-weight:500;
    				&:after {
						width:100%;
						transition: width 1.35s ease-in-out;
					}	
    			}
    			&:hover {
    				font-weight:500;
    	// 			&:after {
					// 	width:100%;
					// 	transition: width 1.35s ease-in-out;
					// }
    			}
			}
			.mobile-infos {
				overflow: hidden;
				position: relative;
				height: auto;
				width: 100%;
				display:none;
				max-height:0;

			    padding: 0 5%;
    			background-color: rgba(0,0,0,0.03);

    			transition: max-height 0.45s ease-in-out;
				.headline {
				    transition: transform .25s ease-in-out 0.3s, opacity 0.15s ease-in-out 0.25s;
				}
				.textBig {
				    transition: transform .25s ease-in-out 0.5s, opacity 0.15s ease-in-out 0.55s;
				}
				.textSmall-wrapper {
				    transition: transform .25s ease-in-out 0.7s, opacity 0.15s ease-in-out 0.65s;
				}
				.close {
				    transition: transform .25s ease-in-out 0.9s, opacity 0.15s ease-in-out 0.85s;
				}
				&.is_open {
					.textBig {
						opacity:1;
						transform: translate(0,0);	
					}
					.textSmall-wrapper {
						opacity:1;
						transform: translate(0,0);
					}
					.close {
						opacity:1;
						transform: translate(0,0);
					}
				}
				@media all and (max-width: 960px) {
					display:block;
				}
			}
		}
		.slider-wrapper {
			background-color: rgba(0,0,0,0.03);
		    position: relative;
		    overflow:hidden;
		    max-height:0;
		    transition:max-height 0.5s ease-in-out;
		    @media all and (max-width: 960px) {
			    display:none;
			}
			.arrows {
				display:flex;
				justify-content: space-between;
				padding:5em 80px;
			    position: absolute;
			    width: 100%;
			    top: 12em;
			    pointer-events: none;
			    z-index:1;
			    @media all and (max-width: 960px) {
					display: none;
				}
				svg {
					width:25px;
					height:25px;
				    padding: 1em;
				    pointer-events: auto;
    				box-sizing: content-box;
    				cursor: pointer;
				}
			}
			.slider {
				overflow: hidden;
				position: relative;
				height: auto;
				width: 100%;
			}
		}
			.slider-item {
			    padding: 0 80px;
		        height: auto;
    			display: inline-block;
    			position: relative;
    			width: 100%;
				@media all and (max-width: 960px) {
					padding: 0;
				}
    			&.is-selected {
    				.headline {
						opacity:1;
						transform: translate(0,0);
					}
					.textBig {
						opacity:1;
						transform: translate(0,0);	
					}
					.textSmall-wrapper {
						opacity:1;
						transform: translate(0,0);
						.column {
								
							&-right {
								// margin-left:5%;
							}
						}
					}
					.close {
						opacity:1;
						transform: translate(0,0);
					}
    			}
				.headline {
					@include f-canelaWeb-l-56;
					color: $darkBlue;
				    padding-top: 2.15em;
				    text-align:center;
			        width: 80%;
    				margin: 0 auto;
				    opacity:0;
				    transform: translate(0, -50px);
				    transition: transform .25s ease-in-out 0.5s, opacity 0.15s ease-in-out 0.45s;
					@media all and (max-width: 960px) {
						display:none;
					}
				}
				.textBig {
					@include f-canelaWeb-l-26;
					color: $darkBlue;
					margin-top: 2.8em;
					letter-spacing: 1px;
				    opacity:0;
				    transform: translate(0, -50px);
				    transition: transform .25s ease-in-out 0.7s, opacity 0.15s ease-in-out 0.65s;
					@media all and (max-width: 960px) {
					    margin-top: 2em;
					}
				}
				.textSmall-wrapper {
					@include f-frutiger-l-19;
					letter-spacing: unset;
					color: $darkBlue;
				    margin-top: 4.15em;
				    display:flex;
				    opacity:0;
				    transform: translate(0, -50px);
				    transition: transform .25s ease-in-out 0.9s, opacity 0.15s ease-in-out 0.85s;
				    @media all and (max-width: 960px) {
						flex-direction: column;
						line-height: 1.4em;
					    margin-top: 2.6em;
					}
					.column {
						width:47.5%;
						@media all and (max-width: 960px) {
							width: 100%;
						}
						&-right {
							margin-left:5%;
							@media all and (max-width: 960px) {
								margin-left: 0;
								margin-top: 1em;
							}
						}
					}
				}
				.close {
					@include f-frutiger-l-16;
			    	text-transform: uppercase;
					color: $darkBlue;
					cursor:pointer;
					margin-top:4em;
				    margin-bottom: 6.5em;
					padding:1em;
					display:block;
			    	// justify-content:center;
			    	// align-items:center;#
			    	text-align: center;
				    opacity:0;
				    transform: translate(0, -50px);
				    transition: transform .25s ease-in-out 1.1s, opacity 0.15s ease-in-out 1.05s;
			    	@media all and (max-width: 960px) {
					    margin-bottom: 1.7em;
						margin-top: 1em;
					}
					svg {
						width: 12px;
			    		height: 12px;
			    		top: 1px;
			    		position: relative;

			    		margin-right: 1em;
					}
				}
			}

	}
	
}