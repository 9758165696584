// TEAM -- Alles Rund um die single-team seite.

.section-teamDetail {
	margin-top: 3em;
	.inner-wrapper {
		.column-wrapper {
			display:flex;
			@media all and (max-width: $mq-1024) {
			    flex-direction: column;
			}
			.column {
				width:50%;
				&-left {
					padding: 0 5.74% 0 0;
					@media all and (max-width: $mq-1024) {
					    padding: 0;
					    width: 100%;
					    max-width: 500px;
					}
					.image-wrapper {
						width: 100%;
					    height: 0;
					    padding-bottom: 138%;

					    // opacity:0;
	    				// // transform: translate(0, -50px);
				    	// transition: opacity 0.45s ease-in-out 0.45s;
						
					}
				}
				&-right {
					@media all and (max-width: $mq-1024) {
					    width: 100%;
					    margin-top: 7em;
					}
					.headline {
						@include f-canelaWeb-l-56;
						letter-spacing: 1px;
						color: $darkBlue;
						margin-top: -.2em;
						letter-spacing:unset;
					}
					.text {
						@include f-canelaWeb-l-26;
						color: $darkBlue;
						margin-top: 2em;

						@media all and (max-width: $mq-1024) {
						    margin-top: 1em;
						}
					}
					.lists {
						margin-top: 6.3em;
						.list-wrapper {
							margin-top: 4em;
							.list-headline {
								@include f-frutiger-l-16;
								text-transform: uppercase;

								letter-spacing: 1px !important;
								color: $darkBlue;
							}
							.list {
								// margin-top: 2em;
							    margin-top: 1.3em;
								.list-item {
									@include f-frutiger-l-19;
									letter-spacing: unset;
									color: $darkBlue;
									position: relative;
									padding-left:1em;
									&:before {
										content: "";
										position: absolute;
										left:0;
										top:0.6em;
										width:5px;
										height:5px;
										border-radius: 6px;
										background-color: $darkBlue;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}