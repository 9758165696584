/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/380846");

  
@font-face {
    font-family: 'FrutigerLTPro-Light';
    src: url('../Fonts/380846_0_0.eot');
    src: url('../Fonts/380846_0_0.eot?#iefix') format('embedded-opentype'),
    url('../Fonts/380846_0_0.woff2') format('woff2'),
    url('../Fonts/380846_0_0.woff') format('woff'),
    url('../Fonts/380846_0_0.ttf') format('truetype');
}
 
  
@font-face {
    font-family: 'FrutigerLTPro-Roman';
    src: url('../Fonts/380846_1_0.eot');
    src: url('../Fonts/380846_1_0.eot?#iefix') format('embedded-opentype'),
    url('../Fonts/380846_1_0.woff2') format('woff2'),
    url('../Fonts/380846_1_0.woff') format('woff'),
    url('../Fonts/380846_1_0.ttf') format('truetype');
}
 
  
@font-face {
    font-family: 'FrutigerLTPro-Bold';
    src: url('../Fonts/380846_2_0.eot');
    src: url('../Fonts/380846_2_0.eot?#iefix') format('embedded-opentype'),
    url('../Fonts/380846_2_0.woff2') format('woff2'),
    url('../Fonts/380846_2_0.woff') format('woff'),
    url('../Fonts/380846_2_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Canela Web';
  	font-weight:500;
  	src: 	url('../Fonts/Canela-Medium-Web.woff2') format('woff2'),
       		url('../Fonts/Canela-Medium-Web.woff') format('woff');
}


@font-face {
  font-family: 'Canela Web';
  src: url('../Fonts/Canela-Light-Web.woff2') format('woff2'),
       url('../Fonts/Canela-Light-Web.woff') format('woff');
  font-weight:  300;
}

$canelaWeb : 'Canela Web', serif;
$frutigerLight : 'FrutigerLTPro-Light', Arial, sans-serif;
$frutigerRoman : 'FrutigerLTPro-Roman', Arial, sans-serif;


/*==================================
=            Canela Web light           =
==================================*/

@mixin f-canelaWeb-l-23 {
  font-family:$canelaWeb;
  font-size: 2.3em;
  line-height:1.1em;
  letter-spacing: 1px;
  font-weight:300;
  @media all and (max-width:$mq-1024) {
    // font-size:1.9em;
  }
}

@mixin f-canelaWeb-l-24 {
  font-family:$canelaWeb;
  font-size: 2.4em;
  line-height:1.4em;
  letter-spacing:1px;
  font-weight:300;
  @media all and (max-width:$mq-1024) {
    // font-size:1.9em;
  }
}

@mixin f-canelaWeb-l-26 {
  font-family:$canelaWeb;
  // font-size: 2.6em;
  line-height:1.28em;
  letter-spacing:1px;
  font-weight:300;
   @media all and (min-width: 0) {
      font-size: 2.2em;
    }
    @media all and (min-width: 400px) {
      font-size: 2.2em;
    }
    @media all and (min-width: 540px) {
      font-size: 2.4em;
    }
    @media all and (min-width: 800px) {
      font-size: 2.6em;
    }
}

@mixin f-canelaWeb-l-34 {
  font-family:$canelaWeb;
  font-size: 3.4em;
  line-height:1.4em;
  // letter-spacing:1px;
  font-weight:300;
  @media all and (min-width: 0) {
      font-size: 2.1em;
    }
    @media all and (min-width: 400px) {
     font-size: 2.1em; 
    }
    @media all and (min-width: 540px) {
      font-size: 2.1em;
    }
    @media all and (min-width: 800px) {
      font-size: 2.4em;
    }
    @media all and (min-width: 900px) {
     font-size: 2.8em; 
    }
    @media all and (min-width: 1024px) {
      font-size: 3.4em;
    }
}

@mixin f-canelaWeb-l-40 {
  font-family:$canelaWeb;
  // font-size: 4em;
  line-height:1.35em;
  // letter-spacing:2px;
  font-weight:300;

   @media all and (min-width: 0) {
      font-size: 2.2em;
      letter-spacing:1px;
    }
    @media all and (min-width: 400px) {
      font-size: 2.2em;
      letter-spacing:1px;
    }
    @media all and (min-width: 540px) {
      font-size: 2.4em;
      letter-spacing:1px;
    }
    @media all and (min-width: 800px) {
      font-size: 2.6em;
      letter-spacing:1px;
    }
    @media all and (min-width: 900px) {
      font-size: 3.2em;
      letter-spacing:1px;
    }
    @media all and (min-width: 1024px) {
      font-size: 4em;
      letter-spacing:1px;
    }
}

@mixin f-canelaWeb-l-42 {
  font-family:$canelaWeb;

  line-height:1.25em;
  letter-spacing:2px;
  font-weight:300;
    @media all and (min-width: 0) {
      font-size:2.3em
    }
    @media all and (min-width: 400px) {
      
    }
    @media all and (min-width: 540px) {
      
    }
    @media all and (min-width: 800px) {
     font-size:3.3em 
    }
    @media all and (min-width: 1024px) {
      font-size: 4.2em;
    }
}

@mixin f-canelaWeb-l-56 {
  font-family:$canelaWeb;
  
  line-height:1.2em;
  // letter-spacing:2px;
  font-weight:300;
    @media all and (min-width: 0) {
      font-size:3.5em
    }
    @media all and (min-width: 400px) {
      font-size:3.5em 
    }
    @media all and (min-width: 540px) {
      font-size:3.5em
    }
    @media all and (min-width: 800px) {
     font-size:4.2em
    }
    @media all and (min-width: 1024px) {
      font-size: 5.6em;
    }
}

@mixin f-canelaWeb-l-73 {
  font-family:$canelaWeb;
  // font-size: 7.3em;
  line-height:1.13em;
  // letter-spacing:2px;
  font-weight:300;
  @media all and (min-width: 0) {
    font-size: 3.5em;
  }

  @media all and (min-width: 540px) {
    font-size: 3.5em;
  }
  @media all and (min-width: 640px) {
    font-size: 4.5em;
  }
  @media all and (min-width: 1024px) {
    font-size: 7.3em;
  }
}

@mixin f-canelaWeb-l-90 {
  font-family:$canelaWeb;
  // font-size: 9em;
  font-size: 3.5em;
  line-height:1.2em;
  font-weight:300;
  @media all and (min-width: 0) {
    font-size: 3.5em;
  }
  @media all and (min-width: 400px) {
    font-size: 4.5em;
  }
  @media all and (min-width: 540px) {
    font-size: 5.4em;
  }
  @media all and (min-width: 640px) {
    font-size: 6.5em;
  }
  @media all and (min-width: 640px) {
    font-size: 8em;
  }
  @media all and (min-width: 1024px) {
    font-size: 9em;
  }
  @media all and (min-width: 1300px) {
    font-size: 10em;
  }
  @media all and (min-width: 1600px) {
    font-size: 11em;
  }
  @media all and (min-width: 2000px) {
    font-size: 12em;
  }
}

/*=========================================
=            Canela Web Medium            =
=========================================*/




/*======================================
=            Frutiger Light            =
======================================*/

@mixin f-frutiger-l-14 {
    font-family:$frutigerLight;
    // font-size: 1.4em;
    line-height:1.4em;
    letter-spacing:1px;
    @media all and (min-width: 0) {
      font-size: 1.2em;
      letter-spacing:1px;
    }
    @media all and (min-width: 400px) {
      font-size: 1.2em;
      letter-spacing:1px;
    }
    @media all and (min-width: 500px) {
      font-size: 1.3em;
      letter-spacing:1px;
    }
    @media all and (min-width: 600px) {
      font-size: 1.4em;
      letter-spacing:2px;
    }
    @media all and (min-width: 1024px) {
      font-size: 1.4em;
      letter-spacing:2px;
    }
}

@mixin f-frutiger-l-15 {
  font-family:$frutigerLight;
  font-size: 1.5em;
  line-height:1.2em;
  letter-spacing:1px;
  @media all and (max-width:$mq-1024) {}
}
@mixin f-frutiger-l-16 {
    font-family:$frutigerLight;
    
    line-height:1em;
    letter-spacing:2px;
    @media all and (min-width: 0) {
      font-size: 1.2em;
      letter-spacing:1px;
    }
    @media all and (min-width: 400px) {
      font-size: 1.2em;
      letter-spacing:1px;
    }
    @media all and (min-width: 500px) {
      font-size: 1.2em;
      letter-spacing:1px;
    }
    @media all and (min-width: 600px) {
      font-size: 1.3em;
      letter-spacing:2px;
    }
    @media all and (min-width: 1024px) {
      font-size: 1.6em;
      letter-spacing:2px;
    }
}

@mixin f-frutiger-l-19 {
    font-family:$frutigerLight;
    font-size: 1.9em;
    line-height: 1.4em;
    letter-spacing:1px;
    @media all and (min-width: 0) {
      font-size: 1.7em;
      line-height: 1.4em;
    }
    @media all and (min-width: 1024px) {
      font-size: 1.9em;
      line-height:1.6em;
    }
}

@mixin f-frutiger-l-22 {
    font-family:$frutigerLight;
    // font-size: 2.2em;
    line-height: 1.3em;
    letter-spacing:3px;
    @media all and (min-width: 0) {
      font-size: 1.6em;
    }
    @media all and (min-width: 400px) {
      font-size: 1.6em;
    }
    @media all and (min-width: 500px) {
      font-size: 1.6em;
    }
    @media all and (min-width: 600px) {
      font-size: 1.6em;
    }
    @media all and (min-width: 700px) {
      font-size: 1.8em;
    }
    @media all and (min-width: 1024px) {
      font-size: 2.2em;
    }
}

/*======================================
=            Frutiger Roman            =
======================================*/

@mixin f-frutiger-r-15 {
    font-family:$frutigerRoman;
    // font-size: 2.8em;
    line-height:1.15em;
    letter-spacing:1px;
    @media all and (min-width: 0) {
      font-size: 2.1em;
    }
    @media all and (min-width: 400px) {
      font-size: 2.2em;
    }
    @media all and (min-width: 500px) {
      font-size: 2.3em;
    }
    @media all and (min-width: 600px) {
      font-size: 2.5em;
    }
    @media all and (min-width: 700px) {
      font-size: 2.7em;
    }
    @media all and (min-width: 1024px) {
      font-size: 2.8em;
    }
}

