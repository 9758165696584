// Modul Element - TextblockBig

.section-TextblockBig {
	.inner-wrapper {
		padding-right:7em;
		@media all and (max-width: $mq-1024) {
			padding-right:0;
		}
		.text {
			@include f-canelaWeb-l-40;
			letter-spacing:1px;
			color: $darkBlue;
		}
	}
	
}