// Modul Element - TeamSlider

.section-TeamSlider {
	overflow:hidden;
	&.has_marginSmall {
		margin-top: 4em;
	}
	.inner-wrapper {
		// max-width: unset;
		// width: 100%;
		.slider-wrapper {
			// overflow:hidden;
			position: relative;
			display:block;
			.arrows {
				display:flex;
				justify-content: space-between;
			    position: absolute;
		        width: 110%;
    			left: -5%;
			    top: 50%;
			    transform:translate(0,-50%);
			    pointer-events: none;
			    z-index:1;
				svg {
					width:35px;
					height:35px;
				    padding: 1em;
				    pointer-events: auto;
    				box-sizing: content-box;
    				cursor: pointer;
				}
			}
			.slider {
				display:block;
				position: relative;
				@media all and (max-width: 700px) {
					width: 90%;
					margin-left: 5%;
			    }
				.slider-item {
				    // width: 29%;
				    width: 33%;
				    position: relative;
				    // display: flex;
				    display: block;

				    margin: 0 0.25%;
				    // margin: 0 0.495%;


					cursor:pointer;
				    @media all and (max-width: 700px) {
				      width: 100%;
				    	margin: 0 0.5%;
				    }
				    &.is-selected {
				    	
	    			}
	    			&:hover {
	    				.text-wrapper {
	    					opacity:1;
	    					.headline {
	    						opacity:1;
						    	transform: translate(0, 0);
	    					}
	    					.readMore-button-wrapper {
	    						opacity:1;
						    	transform: translate(0, 0);
	    					}
	    				}
	    			}
	    			.image-wrapper {
	    				width:100%;
	    				height:0;
	    				// padding-bottom:100%;
	    				padding-bottom:134.3%;
	    				@media all and (max-width: 700px) {
	    					// padding-bottom:134.3%;
	    					// padding-bottom:135%;

					    }
	    			}
	    			.text-wrapper {
	    				position: absolute;
					    width: 100%;
					    height: 100%;
					    top: 0;
					    left: 0;
					    right: 0;
					    bottom: 0;
					    display: flex;
					    justify-content: center;
					    align-items: center;
				        flex-direction: column;
					    background-color: $beige;

    					opacity:0;
    					transition: opacity 0.4s ease-in-out;

					    .headline {
					    	@include f-canelaWeb-l-40;
					    	line-height: 1.05em;
					    	letter-spacing:1px;
					    	color: $darkBlue;
						    width: 80%;
    						text-align: center;

							@media all and (max-width: 700px) {
						      font-size: 4em;
						      letter-spacing:2px;
						    }

					    	opacity:0;
						    transform: translate(0, -50px);
						    transition: transform .45s ease-in-out 0.4s, opacity 0.35s ease-in-out 0.4s;
					    }
	    				.readMore-button-wrapper {
							@include f-frutiger-l-14;
					    	color: $darkBlue;
					    	text-transform: uppercase;
					    	margin-top: 1.5em;
							margin-left: -1em;
					    	padding: 1em;
				    	    display: inline-block;
					    	cursor:pointer;

					    	opacity:0;
						    transform: translate(0, -50px);
						    transition: transform .45s ease-in-out 0.6s, opacity 0.35s ease-in-out 0.6s;


						    display: flex;
						    justify-content: center;
						    align-items: center;
							@media all and (min-width: 700px) {
						      font-size: 1.2em;
						    }
						    @media all and (min-width: 850px) {
						      font-size: 1.3em;
						    }
						    @media all and (min-width: 1024px) {
						      font-size: 1.4em;
						    }
					    	svg {
					    		width: 15px;
					    		height: 15px;
					    		margin-right: 1em;
					    		@media all and (min-width: 1024px) {
							      width: 10px;
							      height: 10px;
							    }
					    	}
						}
	    			}
				}
			}
		} 
	}
}