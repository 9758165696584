// Modul Element - JobsElement

.section-JobsElement {
	.inner-wrapper {
		@media all and (max-width: $mq-1024) {
		    width: 100%;
		}
		.job-wrapper {
			margin-top: 5em;
			@media all and (max-width: $mq-1024) {
			    width: 100%;
			}
			&:first-of-type {
				margin-top: 0;

			}

			&.is_open {
				.pag-wrapper {
					.pag-headline {
						font-weight:500;
	    				&:after {
							width:100%;
							transition: width 1.35s ease-in-out;
						}
					}
				}
				.infobox {
    				.headline {
						opacity:1;
						transform: translate(0,0);
					}
					.textBig {
						opacity:1;
						transform: translate(0,0);	
					}
					.textSmall-wrapper {
						opacity:1;
						transform: translate(0,0);
						.column {
								
							&-right {
								// margin-left:5%;
							}
						}
					}
					.dl-wrapper {
						opacity:1;
						transform: translate(0,0);
					}
					.link-wrapper {
						opacity:1;
						transform: translate(0,0);
					}
					.close {
						opacity:1;
						transform: translate(0,0);
					}
    			}
			}
			.pag-wrapper {

				display: flex;
			    justify-content: space-between;
			    // padding-bottom: 25px;
			    border-bottom: 1px solid $darkBlue;
				@media all and (max-width: $mq-1024) {
			        width: 90%;
    				margin: 0 auto;
				}
				.pag-headline {

					@include f-canelaWeb-l-23;	
					letter-spacing: 1px;
					color: $darkBlue;
					position: relative;
					width: 25%;
				    padding: 0 2em 25px 0;
				    display: flex;
	    			align-items: flex-end;
	    			cursor:pointer;
	    			// transition: font-weight 0.75s ease-in-out;
	    			@media all and (max-width: $mq-1024) {
	                    width: 100%;
					}
	    			&:after {
						content: "";
						position: absolute;
						left:0;
						right:0;
						bottom:0;
						top:auto;
						width:0;
						height:4px;
						background-color: $darkBlue;
						transition: width 0.5s ease-in-out;
					}
	    	// 		&.is_open {
	    	// 			font-weight:500;
	    	// 			&:after {
						// 	width:100%;
						// 	transition: width 1.35s ease-in-out;
						// }
	    	// 		}
				}
			}
			.infobox-wrapper {
				background-color: rgba(0,0,0,0.03);
				position: relative;
		    	overflow:hidden;
				max-height: 0;
				transition:max-height 0.5s ease-in-out;
				
			}
			.infobox {
				// padding:0 80px;
			    padding: 0 80px 104px 80px;
			    @media all and (max-width: $mq-1024) {
		            padding: 0 0 104px 0;
				    width: 90%;
				    margin: 0 auto;
				}
				.headline {
					@include f-canelaWeb-l-56;
					color: $darkBlue;
				    padding-top: 2.15em;
				    text-align:center;
				    
				    opacity:0;
				    transform: translate(0, -50px);
				    transition: transform .25s ease-in-out 0.5s, opacity 0.15s ease-in-out 0.45s;

				 	@media all and (max-width: $mq-1024) {
			            display: none;
					}   
				}
				.textBig {
					@include f-canelaWeb-l-26;
					color: $darkBlue;
					margin-top: 2.8em;
				    
				    opacity:0;
				    transform: translate(0, -50px);
				    transition: transform .25s ease-in-out 0.7s, opacity 0.15s ease-in-out 0.65s;
				}
				.textSmall-wrapper {
					@include f-frutiger-l-19;
					letter-spacing: normal;
					color: $darkBlue;
				    margin-top: 4.15em;
				    display:flex;
				    
				    opacity:0;
				    transform: translate(0, -50px);
				    transition: transform .25s ease-in-out 0.9s, opacity 0.15s ease-in-out 0.85s;
				    @media all and (max-width: $mq-1024) {
		                display: flex;
					    flex-direction: column;
					    margin-top: 3em;
					}
					.column {
						width:47.5%;
						&-left {
							@media all and (max-width: $mq-1024) {
				                width: 100%;
							}
						}
						&-right {
							margin-left:5%;
							@media all and (max-width: $mq-1024) {
				                width: 100%;
								margin-left: 0;
							}
						}
					}
				}
				.dl-wrapper {
					display: flex;
					margin-top: 8em;
					@media all and (max-width: $mq-1024) {
	                    margin-top: 3em;
                        display: flex;
    					align-items: center;
					}
					.dl-text {
						@include f-frutiger-l-14;
						letter-spacing: 2px;
				    	text-transform: uppercase;
						color: $darkBlue;
						cursor:pointer;
			    	    margin: 0;
					    padding: 0;
						
						// display:flex;
				  //   	justify-content:center;
				  //   	align-items:center;

					    display: inline-block;
					    
					    // opacity:0;
					    // transform: translate(0, -50px);
					    transition: transform .25s ease-in-out 1.1s, opacity 0.15s ease-in-out 1.05s;
						@media all and (max-width: $mq-1024) {
		                    font-size: 1.6em;
						}
					}
					svg {
						width: 15px;
			    		height: 15px;
			    		margin-right: 1em;
					}
				}
				.link-wrapper {
				    height: auto;
				    display: flex;
				    justify-content: center;
				    margin-top: 8em;
			        padding-bottom: 0.9em;
			        @media all and (max-width: $mq-1024) {
	                    margin-top: 3em;
					}
					.link {
						@include f-frutiger-l-22;
						color: $darkBlue;
						text-transform: uppercase;
					}
				}
				.close {
					@include f-frutiger-l-16;
			    	text-transform: uppercase;
					color: $darkBlue;
					cursor:pointer;
					margin-top:4em;
				    // margin-bottom: 6.5em;
					padding:1em;
					display:flex;
			    	justify-content:center;
			    	align-items:center;
			    	display: block;
			    	text-align: center;
				    
				    opacity:0;
				    transform: translate(0, -50px);
				    transition: transform .25s ease-in-out 1.3s, opacity 0.15s ease-in-out 1.25s;
			    	@media all and (max-width: $mq-1024) {
	                    margin-top: 3em;
					}
					svg {
						width: 12px;
			    		height: 12px;
			    		position: relative;
			    		top: 1px;
			    		margin-right: 1em;
			    		@media all and (max-width: $mq-1024) {
		                    width: 10px;
		                    height: 10px;
						}
					}
				}
			}
		}
	}
}