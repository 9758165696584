// PREBASE -- Soon Base and Base will be "newname"

.image-wrapper {
	position: relative;
	.image {
		position: absolute;
		left:0;
		top:0;
		right:0;
		bottom:0;
		width:100%;
		height:100%;
		object-fit:cover;
		&-contain {
			object-fit:contain;
		}
	}
}