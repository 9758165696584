// Modul Element - TextblockSmall

.section-TextblockSmall {
	// margin-top: 8em;
	.inner-wrapper {
		// padding-right:7em;
	    display: flex;
    	justify-content: flex-start;

		.text {
			@include f-frutiger-l-19;
			color: $darkBlue;
			max-width:505px;
			// width:100%;

			width: 50%;
			padding-left: 50%;
			box-sizing: content-box;
			letter-spacing: unset;
			@media all and (max-width: $mq-1024) {
				// width: 100%;
				padding-left: 45%;
				// box-sizing: border-box;
			}
			@media all and (max-width: 900px) {
				width: 60%;
				padding-left: 40%;
				// box-sizing: border-box;
			}
			@media all and (max-width: 800px) {
				width: 65%;
				padding-left: 35%;
				// box-sizing: border-box;
			}
			@media all and (max-width: 700px) {
				width: 80%;
				padding-left: 20%;
				// box-sizing: border-box;
			}
			@media all and (max-width: 600px) {
				width: 100%;
				padding-left: 0;
				// box-sizing: border-box;
			}
		}
	}
	
}