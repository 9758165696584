// Modul Element - LinkElement

.section-LinkElement {
	padding-bottom: 0.8em;
	margin-top: 8em;
	.inner-wrapper {
		text-align: center;
		.link {
			@include f-frutiger-l-22;
			color: $darkBlue;
			text-align: center;
			text-transform: uppercase;
			
		}
	}
	
}