// Modul Element - HeadlineSmall

.section-HeadlineSmall {
	margin-top: 14em;
	.inner-wrapper {
		.text {
			@include f-frutiger-l-16;
			line-height:1.28em;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: $darkBlue;
		}
		.link {
			@include f-canelaWeb-l-23;
			letter-spacing: 1px;
			color: $darkBlue;
		}
	}
	
}