// Modul Element - StandardKontent

.section-StandardKontent {
	marign-top: 8em;
	.inner-wrapper {
		.headline {
			@include f-canelaWeb-l-56;
			letter-spacing: 1px;
			color: $darkBlue;
			margin-bottom:35px;
			@media all and (max-width: $mq-1024) {
			    margin-bottom: 25px;
			}
		}
		.text {
			@include f-canelaWeb-l-26;
			color: $darkBlue;
			margin-bottom:35px;
			a {
				color: $darkBlue;
				position: relative;
				&:after {
				    content: "";
				    position: absolute;
				    left: 0;
				    bottom: 0;
				    width: 100%;
				    height: 1px;
				    background-color: $darkBlue;
				    opacity: 0;
				    -webkit-transition: opacity 0.2s ease-in-out;
				    -o-transition: opacity 0.2s ease-in-out;
				    transition: opacity 0.2s ease-in-out;
				}
				&:hover {
					&:after {
						opacity:1;
					}
				}
			}
			@media all and (max-width: $mq-1024) {
			    margin-bottom: 25px;
			}
		}
		.list {
		    margin-bottom:35px;
			@media all and (max-width: $mq-1024) {
			    margin-bottom: 25px;
			}
			.list-item {
				@include f-frutiger-l-19;
				letter-spacing: unset;
				color: $darkBlue;
				position: relative;
				padding-left:1em;
				&:before {
					content: "";
					position: absolute;
					left:0;
					top:0.6em;
					width:5px;
					height:5px;
					border-radius: 6px;
					background-color: $darkBlue;
				}
			}
		}
	}
	
}