// Modul Element - TwoColumns

.section-TwoColumns {
	.inner-wrapper {
		max-width:unset;
		width:100%;
	    padding: 0 0.5%;
	    display:block;
        // display: flex;
    	justify-content: space-between;
    	@media all and (max-width: $mq-1024) {
		    flex-direction: column;
	    	padding: 0;
    	}
		.column {
			width:49.75%;
			height:0;
			padding-bottom:59.15%;
	    	position: relative;
	    	display: flex;
	    	float:left;
	    	@media all and (max-width: $mq-1024) {
		        width: 100%;
    			padding-bottom: 119%;
	    		cursor: pointer;
	    	}
	    	&.is_inactive {
	    		// display:block;
	    		.inactive {
	    			opacity:1;
	    			z-index:1;
	    			pointer-events:auto;
	    		}
	    		.active {
	    			opacity:0;
	    			z-index:1;
	    			pointer-events:none;
	    			.text-wrapper {
						transform:translate(0,50px);
						opacity:0;
	    			}
	    			.close-button-wrapper{
	    				transform:translate(-50%,50px);
	    				opacity:0;
	    			}
	    		}
	    	}
	    	&.is_active {
	    		@media all and (max-width: $mq-1024) {
			        // height: 100vh;
		            z-index: 99;
		    	}
	    		.inactive {
	    			opacity:1;
	    			z-index:1;
	    			pointer-events:none;
	    		}
	    		.active {
	    			opacity:1;
	    			z-index:99999999;
	    			pointer-events:auto;
	    			.text-wrapper {
	    				transform:translate(0,0);
	    				opacity:1;	
	    			}
	    			.close-button-wrapper{
	    				transform:translate(-50%,0);
	    				opacity:1;
	    			}
	    		}
	    	}
	    	&-2 {
	    		float:right;
	    		@media all and (max-width: $mq-1024) {
			        margin-top: 0.6em;
		    	}
	    	}
	    	.inactive {
	    		// transition:opacity 0.2s ease-in-out;
	    		width:100%;
				.image-wrapper {
					width:100%;
					height:100%;
					position: absolute;
				}
				.text-wrapper {
					position: relative;
				    text-align: center;
				    width: 60%;
				    margin: 0 auto;
				    max-width: 320px;
				    padding-top:14em;
				    .headline {
						@include  f-frutiger-r-15;
						color: $darkBlue;
						text-transform: uppercase;
						width:100%;
				    }
				    .readMore-button-wrapper {
				    	@include f-frutiger-l-14;
				    	color: $darkBlue;
				    	text-transform: uppercase;
				    	margin-top: 1.5em;
				    	padding: 1em;
				    	display:flex;
				    	justify-content:center;
				    	align-items:center;
				    	cursor:pointer;
				    	svg {
				    		width: 15px;
				    		height: 15px;
				    		margin-right: 1em;
				    		@media all and (max-width: 600px) {
				    			width: 10px;
				    			height: 10px;
				    		}
				    	}
				    }
					// z-index:1;
				}
	    	}
	    	.active {
	    		background-color: $beige;
	    		width: 100%;
			    height: 100%;
			    position: absolute;
			    transition:opacity .25s ease-in-out;
			    // overflow-y: auto;
	    		@media all and (max-width: $mq-1024) {
    			    position: fixed;
				    height: 100%;
				    width: 100%;
				    top: 0;
				    right: 0;
				    bottom: 0;
				    left: 0;
				    // overflow: auto;
				    // overflow-y: auto;
	    		}
	    		.scroll-wrapper {
	    			overflow-y: auto;
	    			height: 100%;
	    			width:100%;
	    			position: relative;
	    		}
				.text-wrapper {
					position: relative;
				    text-align: left;
				    width: 80%;
				    margin: 0 auto;
				    max-width: 460px;
				    padding-top:14em;
				    transition: transform .45s ease-in-out 0.30s, opacity 0.35s ease-in-out 0.25s;
		    		@media all and (max-width: $mq-1024) {
		    			display: flex;
		    			justify-content: center;
		    			align-items: center;

			            height: auto;
						padding: 8em 0;
					    display: flex;
    					flex-direction: column;
		    		}
		    		.active-headline {
		    			display: none;
		    			@include  f-frutiger-r-15;
						color: $darkBlue;
						text-transform: uppercase;
						text-align: center;
						
		    			@media all and (max-width: $mq-1024) {
			    			display: block;
			    		}
		    		}
				    .text {
				    	@include f-frutiger-l-19;
				    	color: $darkBlue;
			    	    max-height: 360px;
    					overflow: auto;
				    	@media all and (max-width: $mq-1024) {
			    			margin-top: 3em;
		    			    max-height: unset;
    						overflow: auto;
			    		}
				    }
				}
			    .close-button-wrapper{
			    	@include f-frutiger-l-16;
			    	color: $darkBlue;
			    	text-transform: uppercase;
		    	    // bottom: 7.5em;
		    	    bottom: 9%;
				    position: absolute;
				    left: 50%;
				    transform: translate(-50%,0);
			    	padding: 1em;
			    	display:flex;
			    	justify-content:center;
			    	align-items:center;
			    	display: block;
			    	cursor:pointer;
			    	transition: transform .45s ease-in-out 0.6s, opacity 0.35s ease-in-out 0.55s;

		    		@media all and (max-width: $mq-1024) {
	    			    top: 0;
					    right: 0;
					    bottom: auto;
					    transform: translate(0,0);
					    left: auto;
				        padding: 2em 1em 1em 1em;
		    		}
			    	.close-text {
			    		display:inline-block;
			    		@media all and (max-width: $mq-1024) {
			    			display:none;
			    		}
			    	}
			    	svg {
			    		display:inline-block;
		    		    width: 12px;
						height: 12px;
    					top: 1px;
			    		margin-right: 1em;
			    		@media all and (max-width: $mq-1024) {
			    			margin-right: 0;
			    		}
			    	}
			    }
	    	}
		}
	}
}