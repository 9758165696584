// Modul Element - ThreeImages

.section-ThreeImages {
    margin-top: 8em;
    margin-bottom: -4em;
    @media all and (max-width: $mq-1024) {
    	margin-bottom: 0;
    }
	.inner-wrapper {
		.images-wrapper {
			max-width:856px;
			width:78%;
		    display: block;
    		// justify-content: center;
			@media all and (max-width: 900px) {
			    max-width: unset;
    			width: 100%;
			}
			.image-wrapper {
				height:0;
				width:32.5%;
				padding-bottom:38.6%;
				float:left;
				
				@media all and (max-width: 900px) {
				    width: 49.3%;
    				padding-bottom: 59%;
    				
				}
				@media all and (max-width: 500px) {
				    width: 100%;
				    padding-bottom: 119%;
				}
				&-2,
				&-3 {
					margin-left: 6px;
					@media all and (max-width: 900px) {
						
					}
					@media all and (max-width: 500px) {
						display: none;
					}
				}
				&-3 {
					@media all and (max-width: 900px) {
						display: none;
					}
				}
			}
		}
	}
}