// Modul Element - RefSlider

.section-RefSlider {
	background-color: $beige;
	padding:8em 0;
	overflow: hidden;
	@media all and (max-width: $mq-1024) {
		padding:5em 0;
	}
	.inner-wrapper {
		.slider-wrapper {
			// overflow:hidden;
			position: relative;
			display:block;
			.title {
			    @include f-frutiger-l-16;
		        line-height: 1.25em;
		        letter-spacing:2px;
		        text-transform: uppercase;
		        color: $darkBlue;
			    width: 50%;
			    position: absolute;
			    right: 0;
			    top:6%;
			    @media all and (max-width: $mq-1024) {
	    			top:0;
	    			position: relative;
				    max-width: 495px;
				    margin: 0 auto;
				    width: 100%;
	    		}
			}
			.arrows {
				display:flex;
				justify-content: space-between;
			    position: absolute;
		        width: 110%;
    			left: -5%;
			    top: 50%;
			    transform:translate(0,-50%);
			    pointer-events: none;
			    z-index:1;
			    @media all and (max-width: $mq-1024) {
	    			display: none;
	    		}
				svg {
					width:35px;
					height:35px;
				    padding: 1em;
				    pointer-events: auto;
    				box-sizing: content-box;
    				cursor: pointer;
				}
			}
			.slider {
				display:block;
				position: relative;
				.slider-item {
				    width: 100%;
				    position: relative;
				    display: flex;
				    @media all and (max-width: $mq-1024) {
	    			    justify-content: center;
		    		}
				    &.is-selected {
				    	.column-left {
				    		.image-wrapper {
				    			opacity:1;
								transform: translate(0,0);
				    		}
				    	}
				    	.column-right {
							.text-wrapper {
								// opacity:1;
								// transform: translate(0,0);
			    				.headline {
									opacity:1;
									transform: translate(0,0);
								}
								.teasertext {
									opacity:1;
									transform: translate(0,0);	
								}
								.link {
									opacity:1;
									transform: translate(0,0);
								}
								.readMore-button-wrapper {
									opacity:1;
									transform: translate(0,0);
								}
							}
				    	}
	    			}
					.column {
						width:50%;
						&-left {
						    // padding: 0 5.75%;
					        // padding: 0 2.87%;
				            padding: 0 5.74% 0 0;
				            @media all and (max-width: $mq-1024) {
				    			display: none;
				    		}
						    .image-wrapper {
					    	    width: 100%;
							    height: 0;
							    padding-bottom: 118%;
							    opacity:0;
			    				// transform: translate(0, -50px);
						    	transition: opacity 0.45s ease-in-out 0.45s;
						    }
						}
						&-right {
						    display: flex;
    						align-items: center;
							@media all and (max-width: $mq-1024) {
				    			width: 100%;
				    			// width: auto;

								max-width: 500px;
								margin-top: 3em;
				    		}
							.text-wrapper {
							    // top: 22%;
    							position: relative;
							    max-width: 478px;

						    	// opacity:0;
			    				// transform: translate(0, -50px);
						    	// transition: transform .35s ease-in-out 0.4s, opacity 0.25s ease-in-out 0.35s;

						    	@media all and (max-width: $mq-1024) {
				    			    top: 0;
					    		}
								.headline {
									@include f-canelaWeb-l-42;
							    	color: $darkBlue;

							    	opacity:0;
				    				transform: translate(0, -50px);
							    	transition: transform .45s ease-in-out 0.5s, opacity 0.3s ease-in-out 0.45s;
								}
								.teasertext {
									@include f-frutiger-l-19;
							    	color: $darkBlue;
							    	margin-top: 1.1em;

							    	opacity:0;
								    transform: translate(0, -50px);
								    transition: transform .45s ease-in-out 0.8s, opacity 0.3s ease-in-out 0.8s;
								}
								.link {
									@include f-canelaWeb-l-24;
							    	color: $darkBlue;
								    position: relative;
								    display: block;
						    	    // margin-top: 0.2em;

						    	    opacity:0;
								    transform: translate(0, -50px);
								    transition: transform .45s ease-in-out 0.8s, opacity 0.3s ease-in-out 0.8s;
								    // transition: transform .45s ease-in-out 0.8s, opacity 0.3s ease-in-out 0.85s;
								}
								.readMore-button-wrapper {
									@include f-frutiger-l-14;
							    	color: $darkBlue;
							    	text-transform: uppercase;
							    	margin-top: 1.5em;
    								margin-left: -1em;
							    	padding: 1em;
						    	    display: inline-block;
							    	cursor:pointer;

							    	opacity:0;
								    transform: translate(0, -50px);
								    transition: transform .45s ease-in-out 0.8s, opacity 0.3s ease-in-out 0.8s;
								    // transition: transform .25s ease-in-out 1.1s, opacity 0.3s ease-in-out 1.05s;

							    	svg {
							    		width: 15px;
							    		height: 15px;
							    		margin-right: 1em;
							    	}
								}
							}
							
						}
					}
				}
			}
		} 
	}
}