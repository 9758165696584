// Modul Element - TextblockHuge

.section-TextblockHuge {
	// margin-top:0;
	// height:100vh;

	margin-top:20em;
	margin-bottom:8em;

    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: $mq-1024) {
		margin-top:8em;
		margin-bottom:0;
		height: auto;
	    padding-bottom: 0.8em;
	}
	.inner-wrapper {
		text-align: center;
		.text {
			@include f-canelaWeb-l-73;
			color: $darkBlue;
			text-align: center;
		}
		.link {
			@include f-frutiger-l-22;
			color: $darkBlue;
			text-align: center;
    		margin-top: 3.5em;
    		@media all and (max-width: $mq-1024) {
				margin-top: 2em;
			}
		}
	}
	
}